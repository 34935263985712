.text_compliance {
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 21px 21px 0;
  /* width: 60%; */

  font-size: 0.9rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.text_compliance ul {
  margin-top: 5px;
  margin-bottom: 0px;
  padding-left: 20px;
}

div.subheader_compliance {
  padding-top: 12px;
  font-weight: bold;
}

.li_surface {
  float: left;
  width: max-content;
}
.li_temp {
  float: left;
  width: max-content;
}
.placeholer_info {
  float: left;
  /* width: 25%; */
  /* margin-top: 41px; */
  /* margin-left: 35px; */
  /* margin-right: 10px; */
  text-align: justify;
  font-size: small;
}

/* For Desktop View */
@media screen and (min-width: 1540px) {
  /* .app-content {
    display: flex;
  } */
  .ftype_chart {
    width: 33%;
  }
  .placeholer_info {
    width: 25%;
    margin-right: 15px;
  }
}

/* For Desktop View */
@media (min-width: 1038px) and (max-width: 1540px) {
  .ftype_chart {
    width: 47%;
  }
  .placeholer_info {
    width: 47% !important;
    margin-right: 7px;
    margin-top: 35px !important;
  }
  #map-container {
    width: 50% !important;
  }
}

/* For Desktop View */
@media screen and (min-width: 826px) and (max-width: 1038px) {
  .ftype_chart {
    width: 47%;
  }
  .placeholer_info {
    width: 47% !important;
    margin-right: 7px;
    margin-top: 35px !important;
  }
}

@media (min-width: 320px) and (max-width: 826px) {
  .ftype_chart {
    width: 100%;
    float: none !important;
  }
  .css-13aj3tc-MuiChartsSurface-root {
    width: 100%;
  }
  .chart_block {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .placeholer_info {
    width: 100% !important;
    margin-right: 7px;
    margin-bottom: 10px !important;
    margin-top: 35px !important;
  }
}

@media screen and (max-width: 320px) {
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 20rem;
  background-color: #4b5563;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10000;
  left: -7rem;
  padding: 1rem;
  font-size: 0.9rem;
  top: 1rem;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.country_advice_box {
  margin-left: 14px !important;
  padding: 16px !important;
}
.subheader_compliance {
  margin-bottom: 4px;
}
.w3-pale-blue,
.w3-hover-pale-blue:hover {
  color: #000 !important;
  background-color: #f1fbff !important;
}

.surface_advice,
.ua_advice {
  padding-top: 4px;
  padding-bottom: 4px;

  text-decoration: underline;
}
span.w3-badge.tooltip {
  border: none;
}
