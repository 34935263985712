.ftype_chart {
  float: left;
  margin-top: 36px;
  /* width: 40%; */
  /* ;
    height: 630px; */
  /* border: solid 1px lightblue; */
  margin-left: 20px;
  margin-bottom: 5px;
}
@media (max-width: 1199px) {
  /* .ftype_chart {
    width: 100%;
    margin-left: 0px;
  } */
  .text_compliance {
    margin: 0px;
    padding-top: 0px;
  }
}

.chart_title {
  /* margin-left: 71px; */
  padding-bottom: 0px;
  margin-top: 10px;
  float: left;
}

.recharts-wrapper {
  /* width: 300px !important;
    height: 400px !important; */
  float: left;
  /* height: 300px !important; */
  margin-left: -49px;
  margin-top: 15px;
}

.MuiChartsReferenceLine-line {
  stroke: red !important;
  stroke-dasharray: 4;
  stroke-width: 1;
}
.recharts-legend-item .recharts-surface {
  width: auto;
  height: auto;
}
.chart_block {
  /* width: 49% !important; */
  float: left;
  /* margin-left: -45px; */
}
.chart_block h6 {
  text-align: center;
  /* margin-left: 65px; */
}
.separator {
  border: solid 1px gray;
  float: left;
  height: 630px;
  margin-left: 50px;
}
.summary_tbl {
  position: relative;
  margin-left: 71px;
  margin-bottom: 20px;
  float: left;
}
.compliance_txt {
  margin-left: 71px;
  margin-bottom: 14px;
  text-align: left;
  float: left;
}
.exmption_tbl {
  float: left;
  display: flex;
  flex-flow: column;
  margin-left: 50px;
  margin-top: 40px;
}
.w3-badge {
  border-radius: 8% !important;
}

@media (max-width: 768px) {
  /* .ftype_chart {
    width: 100%;
    margin-left: 0px;
  } */
  .text_compliance {
    margin: 0px;
    padding-top: 0px;
    width: 100%;
  }
  .recharts-wrapper {
    float: left;
    height: 300px !important;
    margin-left: -32px;
  }
}
