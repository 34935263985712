#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
}

.level1 {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.mychart_title {
  font-weight: bold;
}

.app-content {
  /* display: flex; */
  /* flex-grow: 1; */
  justify-content: center;
  width: 82%;
  margin: auto;
}

@media (max-width: 1310px) {
  .app-content {
    width: 90%;
  }
}

@media (max-width: 1199px) {
  .app-content {
    width: 90%;
  }
}

header,
main,
footer {
  flex-shrink: 0;
}

html,
body {
  height: 100%;
}
body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif !important;
  /* font-size: 62.5%; */
  display: flex;
}
