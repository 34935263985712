.country-map {
  /* width: 40%; */
  margin-top: 30px;
  float: left;
  /* position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;*/
}

/* .mapboxgl-ctrl-bottom-right {
    display: none;
} */
.mapboxgl-canvas {
  border: solid 2px gray;
}

.iconmap {
  width: 10px;
}

#map-container {
  /* display: flex; */
  /* height: 75%; */
  position: relative;
  width: 35%;
  float: left;
}

@media (max-width: 1199px) {
  #map-container {
    height: 80%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  #map-container {
    height: 95%;
    width: 100%;
  }
}

#map {
  /* position: absolute; */
  /* top: 100px; */
  bottom: 0;
  width: 100%;
  height: 500px;
  top: 34px;
  z-index: 100;
}

.red_marker {
  background-color: red;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  /* border: .5px solid white; */
}

.green_marker {
  background-color: green;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  /* border: .5px solid white; */
}
.orange_marker {
  background-color: orange;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  /* border: .5px solid white; */
}
.black_marker {
  background-color: black;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  /* border: .5px solid white; */
}
.gbon_tool {
  position: relative;
  width: 45%;
}

.gbon_tool_txt {
  margin-right: 50px;
}

#menu_toggle {
  background: #fff;
  position: absolute;
  z-index: 1000;
  top: 190px;
  right: 10px;
  border-radius: 3px;
  width: 69px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
}

#menu_toggle a {
  font-size: 10px;
  color: #404040;
  display: block;
  margin: 0;
  padding: 0;
  padding: 10px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  text-align: center;
}

#menu_toggle a:last-child {
  border: none;
}

#menu_toggle a:hover {
  background-color: #f8f8f8;
  color: #404040;
}

#menu_toggle a.active {
  background-color: #3887be;
  color: #ffffff;
}

#menu_toggle a.active:hover {
  background: #3074a4;
}

.link-oscar {
  color: #3887be;
  text-decoration: none;
}
a.link-oscar:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

/**
* Set rules for how the map overlays
* (information box and legend) will be displayed
* on the page. */
.map-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;
  margin-right: 20px;
  font-size: 0.9rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif;
  overflow: auto;
  border-radius: 3px;
}

#features {
  top: 0;
  height: 100px;
  margin-top: 20px;
  width: 250px;
}

#legend {
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0 0 0 0.1);
  line-height: 18px;
  height: fit-content;
  margin-bottom: 40px;
  width: fit-content;
  position: absolute;
  z-index: 10000;
  top: 420px;
  left: 6px;
}

.legend-key {
  display: inline-block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  float: left;
  margin-top: 0.2rem;
  pointer-events: none;
}

.legend-class-clickable {
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.unselected {
  opacity: 0.3;
}

span.legend-class-not-clickable {
  opacity: 100 !important;
  cursor: auto !important;
}

tbody.p-datatable-tbody {
  font-size: 0.9rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif;
}

span.p-column-title {
  font-size: 0.9rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif;
}

.p-input-icon-left input.p-inputtext.p-component {
  font-size: 0.9rem !important;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif !important;
}

.country-disclaimer {
  margin-top: 37px;
  font-size: x-small;
  background-color: cornsilk;
  text-align: center;
}
