.wdqms-footer {
  display: flex;
  justify-content: space-between;
  background-color: #f4f7fb;
  /* max-height: 2.5vh; */
  padding: 4px;
  border-top: 0.5px solid #c6d2e1;
  /* position: absolute; */
  bottom: 0;
  /* top: 10; */
  width: 100%;
  height: max-content;
}

.version,
.wmo_copy {
  width: -webkit-fill-available;
  max-width: fit-content;
  font-size: x-small;
}
.wmo_copy {
  margin-right: 1rem;
}
.version {
  margin-left: 1rem;
}
.disclaimer {
  font-size: xx-small;
  text-align: center;
}
