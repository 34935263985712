.home_left {
  float: left;
  /* width: 70%; */
}
.home_right {
  float: left;
  /* width: 30%; */
}

.home_image {
  height: auto;
  float: left;
  width: -webkit-fill-available;
  width: 50%;
}
.home_right .stats,
.home_left image {
  padding: auto;
}

.country-page-filter-home {
  float: none;
}
.home_content {
  display: flex;
  flex: wrap;
  justify-content: center;
  margin-top: 24px;
}
.home-content-wrapper {
  display: contents;
}
/* For Desktop View */
@media screen and (min-width: 1472px) {
  .home_left {
    float: left;
  }
  .home_right {
    float: left;
  }
}

/* For Desktop View */
@media (min-width: 860px) and (max-width: 1472px) {
  .home_left {
    float: left;
    width: 70%;
  }
  .home_right {
    float: left;
    width: 30%;
  }
  .home_image {
    height: auto;
    float: left;
    width: -webkit-fill-available;
    width: 100%;
  }
}

/* For Desktop View */
@media screen and (min-width: 550px) and (max-width: 860px) {
  .home_content {
    display: block;
  }
  .home_left {
    width: 100%;
  }
  .home_right {
    width: 100%;
  }
  .home_right .stats {
    margin: auto;
    width: 50%;
  }
}

@media (min-width: 320px) and (max-width: 550px) {
  .home_content {
    display: block;
  }
  .home_left {
    width: 100%;
  }
  .home_right {
    width: 100%;
  }
  .home_right .stats {
    margin: auto;
    width: 50%;
  }
}

@media screen and (max-width: 320px) {
  .home_content {
    display: block;
  }
  .home_left {
    width: 100%;
  }
  .home_right {
    width: 100%;
  }
  .home_right .stats {
    margin: auto;
    width: 50%;
  }
}
