.country-page-filter {
    float: left;
    margin:auto;
    /* border: solid 1px darkgrey;
    padding: 12px; */
    width: 90%;
}

.title-map {
    padding-left: 1rem;
    margin-top: 2rem;
    font-size: 1.5rem;
    width: 90%;
    margin: auto;
    margin-top: 5px;
}
#menu {
    padding: 2rem;
    /* margin-bottom: 2rem; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 12%;
    background-color: #fcfaf9;
    border-radius: 8px;
    font-size: 0.8rem;
    /* margin-top: 10px; */
  }
  
  @media (max-width: 960px) {
    #menu {
      height: 15%;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      overflow-y: auto;
    }
    .gbon_tool {
      position: relative;
      width: 100%;
  }
  }
  
  @media (max-width: 775px) {
    #menu {
      font-size: 0.7rem;
    }
    .gbon_tool {
      position: relative;
      width: 100%;
  }
  }
  
  @media (max-height: 1000px) {
    #menu {
      padding: 0;
    }
  }
  
  @media (max-height: 800px) {
    #menu {
      padding: 0;
      font-size: 0.6rem;
    }
  }
  
  .menu-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 13px;
  }
/* original: https://css-tricks.com/styling-a-select-like-its-2019/ */
.select-css {
    display: block;
    line-height: 1.3;
    padding: 0.4rem 1.3rem 0.4rem 0.5rem !important;
    width: 7rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    padding-right: 3rem;
  }
  .select-css::-ms-expand {
    display: none;
  }
  .select-css:hover {
    border-color: #888;
  }
  .select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
/* 
.select-years, .select-quarter {
    float: left;
    margin-top: 15px;
}
.select-quarter {
    margin-right: 20px;
} */