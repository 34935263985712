.presentation {
  width: 70%;
  margin: auto;
  font-size: 1rem;
}

.presentation p {
  text-align: justify;
  margin-top: 1rem;
}

.compliance {
  width: 82%;
  margin: auto;
  display: flex;
  flex-grow: 1;
  text-align: justify;
}

.releasenotes {
  margin-left: 40px;
}
