.obstbl {
  margin-top: 5px;
  /* border: solid 1px; */
  position: relative;
  clear: both;
  width: 100%;
  /* font-size: .7rem; */
}
tr,
td {
  /* border: solid 1px; */
  text-align: center;
}
.year_quarter_col {
  width: 175px;
}
.th_bg {
  background-color: #f4f6fb;
}
.obstbl_wrapper {
  /* height: 200px; */
  overflow: auto;
  margin-bottom: 10px;
  border: solid 1px lightblue;
  /* border-right: none; */
  /* margin-top: 38px; */
}

.obstbl a {
  color: darkblue;
  text-decoration: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0rem !important;
}

tr.row-reporting {
  color: green;
}

tr.row-reporting-gap {
  color: orange;
}

tr.row-reporting-something {
  color: red;
}

tr.row-not-reporting {
  color: black;
}

.p-tag-no_data {
  background-color: black;
  width: max-content;
}
.p-tag-compliant {
  background-color: green;
  width: max-content;
}
.p-tag-frequency_issue {
  background-color: orange;
  width: max-content;
}
.p-tag-availability_issue {
  background-color: red;
  width: max-content;
}

.p-tag-exemption {
  background-color: white;
  border: 1px solid red;
  color: black;
  width: max-content;
}

span.p-tag.p-component.exemption {
  /* border-color: red; */
  border: 1px solid red;
}
span.p-tag {
  margin: 1px;
}

.badge_station .tooltiptext {
  left: -5rem;
  top: -2rem;
  width: 11rem;
  padding: 4px;
  font-size: xx-small;
  background-color: #fffcf5;
  color: black;
  border: 0.2px solid #cdcdcd;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serifsans-serifsans-serif;
  text-align: center;
}

.badge_station {
  border: none;
}
